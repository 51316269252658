import {
  Linkedin,
  Twitter,
  Facebook,
  Instagram,
  Medium,
  Github,
} from "@/assets/images/icons/social-icons/index.js";

let socialHandles = [
  {
    icon: Linkedin,
    link: "/",
  },
  {
    icon: Twitter,
    link: "/",
  },
  {
    icon: Facebook,
    link: "/",
  },
  {
    icon: Instagram,
    link: "/",
  },
  {
    icon: Medium,
    link: "/",
  },
  {
    icon: Github,
    link: "https://github.com/getboarded-technology/alfred/tree/chirag",
  },
];

export default socialHandles;
